// 共通
// 初回登録

const state = {
  sLblMailAddress: 'メールアドレス',
  sLblVerifyCode: '確認コード',
  sLblPassword: 'パスワード',
  sLblComName: '企業名',
  sFirstRegiStep: { // 登録ステップ
    in_mailaddress: 1,  // メールアドレス入力
    in_verify: 2,       // 確認コード入力
    in_password: 3,     // パスワード入力
    in_done: 4          // 完了
  },
  sPasswordInputArea: { // パスワード入力エリア
    original: 1,  // パスワード
    confirm: 2    // パスワード（確認用）
  },
};

export default {
  namespaced: true,
  state,
  // getters,
  // mutations,
  // actions
}
