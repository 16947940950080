// クライアント
// 職場情報

const state = {
  sLblWpName: '職場名'
};
// const getters = {
//
// };
// const mutations = {
//
// };

export default {
  namespaced: true,
  state,
  // getters,
  // mutations,
  // actions
}
