// クライアント
// 企業情報

// import { createStore } from 'vuex'

//import common from '../modules/common/common'

const state = {
  sLblComName: '企業名',
  sLblComKana: '企業名カナ',
  sLblComZip: '郵便番号',
  sLblComAddress: '住所',
  sLblComTel: '電話番号',
  sLblComManagerName: '担当者名',
  sLblComMailAddress: 'メールアドレス',
  sLblContractType: '契約形態'
};
// const getters = {
//
// };
// const mutations = {
//
// };

export default {
  namespaced: true,
  state,
  // getters,
  // mutations,
  // actions
}
