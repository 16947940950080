// 共通
// ログインとか

import UIkit from 'uikit'

const state = {
  sIsLogined: false,
  sLoginId: '',
  sLoginPw: '',
  sAliasId:"",
  sComId: '',
  sComName: '',
  sMailAddress: '',
  sUserType: '',
  sUserCode: '',
  sUserName: '',
  sRegiStatus: '',
  sScreenTitle: '',
  sUpdateModeDisplayName: '',
  sAbleUpdate: false,
  sUpdateModalMessage: '',
  sIsLoading: false,
  sDataLoading: false,
  sNotificationDisplayTime: 2000,
  sServerProcessing: false
};
// const getters = {
// };
const mutations = {
  // ログイン処理
  doLogin: (state, val)=> {
    state.sAliasId = val.vAliasId
    state.sIsLogined = true;
    state.sLoginId = val.vLoginId;
    state.sLoginPw = val.vLoginPw;
    state.sComId = val.vComId;
    state.sComName = val.vComName;
    state.sMailAddress = val.vMailAddress;
    state.sUserType = val.vUserType;
    state.sUserCode = val.vUserCode;
    state.sUserName = val.vUserName;
    state.sRegiStatus = val.vRegiStatus;
    if(val.mode == 1){
      sessionStorage.setItem('storageAliasId', val.vAliasId);
      sessionStorage.setItem('storageId', val.vLoginId);
      sessionStorage.setItem('storagePw', val.vLoginPw);
    }
  },
  // ログアウト処理
  doLogout: (state)=> {
    state.sIsLogined = false;
    state.sAliasId = "";
    state.sLoginId = '';
    state.sLoginPw = '';
    state.sComId = '';
    state.sComName = '';
    state.sMailAddress = '';
    state.sUserType = '';
    state.sUserName = '';
    // sessionStorage.clear('');
    sessionStorage.removeItem('storageAliasId');
    sessionStorage.removeItem('storageId');
    sessionStorage.removeItem('storagePw');
  },
  // 画面タイトル
  doSetScreenTitle: (state, val)=> {
    state.sScreenTitle = val.vScreenTitle;
  },
  // レコード操作モード表示名セット
  doSetUpdateModeDisplayName: (state, val)=> {
    switch (val.vUpdateMode) {
      case 1:
        state.sUpdateModeDisplayName = '追加';
        break;
      case 2:
        state.sUpdateModeDisplayName = '変更';
        break;
      case 3:
        state.sUpdateModeDisplayName = '削除';
        break;
      default:
        state.sUpdateModeDisplayName = '';
        break;
    }
  },
  // 更新モーダル内メッセージ
  doSetUpdateModalMessage: (state, val)=> {
    switch (val.vUpdateMode) {
      case 1:
        state.sUpdateModalMessage = '更新しますか？';
        break;
      case 2:
        state.sUpdateModalMessage = '更新しますか？';
        break;
      case 3:
        state.sUpdateModalMessage = '削除しますか？';
        break;
      default:
        state.sUpdateModalMessage = '';
        break;
    }
  },
  // 値チェック処理（input）
  doCheckInput: (state, val)=> {
    var res = true;
    if (!val.vVal) {
      res = false;
    } else if (!val.vVal.trim()) {
      res = false;
    }
    if (!res) {
      state.sAbleUpdate = false;
      UIkit.notification({
      message: '<span uk-icon="warning"></span>【' + val.vLabel + '】入力が必要です',
      status: 'warning',
      pos: 'top-right',
      timeout: state.sNotificationDisplayTime,
      'z-index': 10000
      });
    }
  },
  // 値チェック処理（select）
  doCheckSelect: (state, val)=> {
    var res = true;
    // 0はいい
    if (!val.vVal && val.vVal !== 0) {
      res = false;
    }
    if (!res) {
      state.sAbleUpdate = false;
      UIkit.notification({
      message: '<span uk-icon="warning"></span>【' + val.vLabel + '】選択が必要です',
      status: 'warning',
      pos: 'top-right',
      timeout: state.sNotificationDisplayTime
      });
    }
  },
  // 値チェック処理（object）
  doCheckObjectLength: (state, val)=> {
    var res = true;
    if (val.vVal.length == 0) {
      res = false;
    }
    if (!res) {
      state.sAbleUpdate = false;
      UIkit.notification({
      message: '<span uk-icon="warning"></span>【' + val.vLabel + '】1つ以上必要です',
      status: 'warning',
      pos: 'top-right',
      timeout: state.sNotificationDisplayTime
      });
    }
  },
  // 更新メッセージ表示処理
  doNotificationUpdate: (state, val)=> {
    if (val.vMode == 0) {
      // 処理が正常に終了
      UIkit.notification({
      message: '<span uk-icon="check"></span>' + '更新しました',
      status: 'success',
      pos: 'top-right',
      timeout: state.sNotificationDisplayTime
      });
    } else {
      // 何かエラー
      UIkit.notification({
      message: '<span uk-icon="bolt"></span>' + '更新に失敗しました',
      status: 'warning',
      pos: 'top-right',
      timeout: state.sNotificationDisplayTime
      });
    }
  },
  // データなしメッセージ表示処理
  doNotificationDataNone: ()=> {
    UIkit.notification({
    message: '<span uk-icon="bolt"></span>' + 'データがありません',
    status: 'warning',
    pos: 'top-right',
    timeout: state.sNotificationDisplayTime
    });
  },
  // 企業情報更新処理
  doUpdateCompany: (state, val)=> {
    state.sComName = val.vComName;
    state.sMailAddress = val.vMailAddress;
    state.sUserName = val.vUserName;
    state.sRegiStatus = val.vRegiStatus;
  },
  // サーバー処理中
  doStartServerProcess: (state)=> {
    state.sServerProcessing = true;
    // bodyでマウスカーソル変える
    var body = document.getElementById('upper_layer');
    body.style.cursor = 'wait';
    console.log('server-processing = true');
  },
  // サーバー処理終了
  doEndServerProcess: (state)=> {
    window.setTimeout(()=> {
      // bodyでマウスカーソル戻す
      var body = document.getElementById('upper_layer');
      body.style.cursor = 'default';
      state.sServerProcessing = false;
      console.log('server-processing = false');
    }, 50)
  },
  doIsServerProcessing: (state)=> {
    return state.sServerProcessing;
  }
};
// const actions = {
//   // doLogin({state}, name) {
//   //   state.sIsLogined = true;
//   //   state.loginedName = name;
//   // }
// };

export default {
  namespaced: true,
  state,
  // getters,
  mutations,
  // actions
}
