// 共通
// ログイン

const state = {
  sLblId: 'ID',
  sLblPassword: 'パスワード',
};

export default {
  namespaced: true,
  state,
  // getters,
  // mutations,
  // actions
}
