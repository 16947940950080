// クライアント
// 社員情報

const state = {
  sLblEmpCode: '社員番号',
  sLblEmpName: '社員名',
  sLblEmpKana: '社員名カナ',
  sLblEmpWpId: '職場名',
  sLblEmpBirthday: '生年月日',
  sLblEmpSex: '性別',
  sLblEmpMailAddress: 'メールアドレス',
  sLblLoginId: 'ログインID',
  sLblLoginPw: 'パスワード',
  sLblRetireStatus: '退職状態',
  sLblRetireDate: '退職年月日',
  sLblErrorDetail: '取込エラー内容',
  sFileName: 'ストレスチェック社員情報.xlsx',
};

export default {
  namespaced: true,
  state,
  // getters,
  // mutations,
  // actions
}
