// インテリジェント
// ホーム

const state = {
  sLblPreparation: '実施予定',
  sLblOnGoing: '実施中',
  sLblToDo: '次の処理',
  sLblScAnswerPeriod: 'アンケート期間',
  // sDispMenuMode: {  // 表示メニュー
  //   operation: 1, // 運用メニュー
  //   system: 2     // システムメニュー
  // },
  sScStatus: {
    preparation: 0,   // 準備中
    requested: 1,     // 依頼済み
    ongoing: 2,       // 実施中
    inocr: 3,         // OCR済みファイル取込
    inanalysis: 4,    // 厚労省ツール分析
    underanalysis: 5, // ストレスプロフィール印刷
    printed: 6,       // ストレスプロフィール印刷
    billinfo: 7,      // 請求情報作成
    performed: 8,     // 実施済み
    drop: 10,         // 取り下げ
  }
};

export default {
  namespaced: true,
  state,
  // getters,
  // mutations,
  // actions
}
