import { createStore } from 'vuex'


// ??
import common from './modules/common/common'
import firstregistration from './modules/common/firstregistration'
import login from './modules/common/login'

// ??????
import company from './modules/customer/company'
import workplace from './modules/customer/workplace'
import employee from './modules/customer/employee'
import sc_config from './modules/customer/sc_config'

// ???
import respondent_home from './modules/respondent/respondent_home'
import answer from './modules/respondent/answer'

// ????????
import intelligent_home from './modules/intelligent/intelligent_home'
import sc_matter from './modules/intelligent/sc_matter'

export default createStore({
  modules: {
    common,
    firstregistration,
    login,
    company,
    workplace,
    employee,
    sc_config,
    respondent_home,
    answer,
    intelligent_home,
    sc_matter
  },
  state: {
    login_status_i:false,
    login_status_c:false,
    alias_id:null,
    email:null,
    pass:null,
    sc_id:0,
    group_analysis:null,
    analysis_group_code:null,
    page:0,
    first_registration:false,
    grouping:false,
    page_title:"",
    start_date:null,
    end_date:null,
  },
  mutations: {
    setLogin_i(state,data){
      state.login_status_i = data.status
      state.email = data.email
      state.pass = data.pass
    },
    setLogin_c(state,data){
      state.login_status_c = data.status
      state.alias_id = data.alias_id
      state.email = data.email
      state.pass = data.pass
    },
    setLogout_i(state){
      state.email = ""
      state.pass = ""
      state.login_status_i = false
    },
    setLogout_c(state){
      state.alias_id = ""
      state.email = ""
      state.pass = ""
      state.login_status_c = false
    },
    set_group_analysis(state,data){
      if(data.group_analysis == 1){
        state.group_analysis = data.group_analysis
      }
    },
    set_analysis_group_code(state,data){
      state.analysis_group_code = data.analysis_group_code
    },
    reset_analysis_group_code(state){
      state.group_analysis = null
      state.analysis_group_code = null
    },
    setImplementationID(state,data){
      state.sc_id = data.id
    },
    resetImplementationID(state){
      state.sc_id = 0
    },
    change_page(state,data){
      state.page = data.page_num
    },
    doFirstRegistration(state,data){
      state.first_registration = data.first_registration
      state.alias_id = data.alias_id
    }
  },
  actions: {
  }
})
