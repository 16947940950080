// 回答者
// 回答

const state = {
  sScId: '',
  sScStatusForScreen: 0,
  sAnswerScreen: {  // 回答ページの表示画面
    preparation: 1, // 準備中
    ongoing: 3,     // 実施中
    performed: 7    // 実施済
  },
  sQuestionType: {  // 質問種別
    category: 1,    // カテゴリ
    question: 2     // 各質問
  },
  sAnswerStep: {    // 回答ステップ
    introduction: 1,  // 導入
    question: 2,      // 質問
    result: 3         // 回答結果一覧
  },
  sCreateQuestionSituation: { // 質問リスト作成状況
    first: 1,   // 初期処理
    next: 2,    // 次の質問へ
    previous: 3 // 前の質問へ
  },
  sCategoryType: {  // 質問カテゴリ種別
    a: 1,
    b: 2,
    c: 3,
    d: 4
  },
  sAnswerAllDone: { // 全問回答済み判定
    not: false, // 未
    done: true  // 回答済み
  }
};

export default {
  namespaced: true,
  state,
  // getters,
  // mutations,
  // actions
}
