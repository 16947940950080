// クライアント
// ストレスチェック設定

const state = {
  sLblScAnswerStartDate: 'アンケート回答開始年月日',
  sLblScAnswerEndDate: 'アンケート回答終了年月日',
  sLblScGroupAnalysis: '集団分析の実施',
  sLblScConfAnswer: '回答形式',
  sLblScConfNotice: '結果通知形式',
  sLblScStatus: '実施状態',
  sLblWorkGroup: '分析グループ',
  sLblScTarget: '対象者'
};

export default {
  namespaced: true,
  state,
  // getters,
  // mutations,
  // actions
}
