import {
  createRouter,
  createWebHistory
} from 'vue-router'

const routes = [{
  path: '/login',
  components: {
    default: () => import('../components/pages/common/Login.vue')
  }
},
{
  path: '/first-registration',
  components: {
    default: () => import('../components/pages/common/FirstRegistration.vue')
  }
},
{
  path: '/',
  components: {
    default: () => import('../components/globals/Index.vue')
  },
  children: [{
      path: '/customer-home',
      components: {
        default: () => import('../components/pages/customer/Home.vue')
      }
    },
    {
      path: '/customer-company',
      components: {
        default: () => import('../components/pages/customer/Company.vue')
      }
    },
    {
      path: '/customer-workplace',
      components: {
        default: () => import('../components/pages/customer/Workplace.vue')
      }
    },
    {
      path: '/customer-employee',
      components: {
        default: () => import('../components/pages/customer/Employee.vue')
      }
    },
    {
      path: '/customer-sc-config',
      components: {
        default: () => import('../components/pages/customer/ScConfig.vue')
      }
    },
    {
      path: '/respondent-home',
      components: {
        default: () => import('../components/pages/respondent/Home.vue')
      }
    },
    {
      path: '/respondent-answer',
      components: {
        default: () => import('../components/pages/respondent/Answer.vue')
      }
    },
    {
      path: '/respondent-results',
      components: {
        default: () => import('../components/pages/respondent/ResultList.vue')
      }
    },
    {
      path: '/respondent-changePW',
      components: {
        default: () => import('../components/pages/respondent/changePW.vue')
      }
    },
    {
      path: '/intelligent-index',
      components: {
        default: () => import('../components/pages/intelligent/Index.vue')
      },
      children: [{
          path: '/intelligent-home',
          components: {
            default: () => import('../components/pages/intelligent/Home.vue')
          }
        },
        {
          path: '/intelligent-ocr-result-capture',
          components: {
            default: () => import('../components/pages/intelligent/OcrResultCapture.vue')
          }
        },
        {
          path: '/intelligent-analysis',
          components: {
            default: () => import('../components/pages/intelligent/Analysis.vue')
          }
        },
      ]
    }
  ]
},
{
  path: '/intelligent-menu',
  component: () => import('../components/intelligent/menu.vue')
},
{
  path: '/intelligent-home',
  component: () => import('../components/intelligent/home.vue')
},
{
  path: '/intelligent-login',
  component: () => import('../components/intelligent/login.vue')
},
{
  path: '/intelligent-client_lists',
  component: () => import('../components/intelligent/client_lists.vue')
},
{
  path: '/intelligent-company_info/:catchAll(.*)',
  component: () => import('../components/intelligent/company_info.vue')
},
{
  path: '/intelligent-employee_list/:catchAll(.*)',
  component: () => import('../components/intelligent/employee_list.vue')
},
{
  path: '/intelligent-stress_check_history/:catchAll(.*)',
  component: () => import('../components/intelligent/stress_check_history.vue')
},
{
  path: "/intelligent-detail/:catchAll(.*)",
  component: () => import("../components/intelligent/detail.vue")
},
{
  path: "/intelligent-company_detail/:catchAll(.*)",
  component: () => import("../components/intelligent/company_detail.vue")
},
{
  path: "/intelligent-company_info/:catchAll(.*)",
  component: () => import("../components/intelligent/company_info.vue")
},
{
  path: "/intelligent-employee_list/:catchAll(.*)",
  component: () => import("../components/intelligent/employee_list.vue")
},
{
  path: "/intelligent-employee_detail/:catchAll(.*)",
  component: () => import("../components/intelligent/employee_detail.vue")
},
{
  path: '/about',
  name: 'About',
  // route level code-splitting
  // this generates a separate chunk (about.[hash].js) for this route
  // which is lazy-loaded when the route is visited.
  component: () => import( /* webpackChunkName: "about" */ '../views/About.vue')
},
{
  path: '/intelligent/:catchAll(.*)',
  components: {
    default: () => import("../components/intelligent/home.vue")
  }
},
{
  path: '/client-menu',
  components: {
    default: () => import("../components/client/menu.vue")
  }
},
{
  path: "/client-login",
  components: {
    default: () => import("../components/client/login.vue")
  }
},
{
  path: "/client-sign_up",
  components: {
    default: () => import("../components/client/sign_up.vue")
  }
},
{
  path: "/client-info_company",
  components: {
    default: () => import("../components/client/info_company.vue")
  }
},
{
  path: "/client-info_busyo",
  components: {
    default: () => import("../components/client/info_busyo.vue")
  }
},
{
  path: "/client-stress_check_history",
  components: {
    default: () => import("../components/client/stress_check_history.vue")
  }
},
{
  path: "/client-info_employee",
  components: {
    default: () => import("../components/client/info_employee.vue")
  }
},
{
  path: "/client-request_stresscheck",
  components: {
    default: () => import("../components/client/request_stresscheck.vue")
  }
},
{
  path: "/client-pdf_list",
  components: {
    default: () => import("../components/client/pdf_list.vue")
  }
},
{
  path: "/client-answer_status",
  components: {
    default: () => import("../components/client/answer_status.vue")
  }
},
{
  path: "/client-request_lists",
  components: {
    default: () => import("../components/client/request_lists.vue")
  }
},
{
  path: "/client-change_password",
  components: {
    default: () => import("../components/client/change_password.vue")
  }
},
{
  path: "/expirs",
  components: {
    default: () => import("../components/client/expirs.vue")
  }
},
{
  path: "/error",
  components: {
    default: () => import("../components/client/error.vue")
  }
},
{
  path: "/approval/:catchAll(.*)",
  components: {
    default: () => import("../components/client/approval.vue")
  }
},
{
  path: "/client-registration1:catchAll(.*)",
  components: {
    default: () => import("../components/client/registration1.vue")
  }
},
{
  path: "/client-grouping_busyo",
  components: {
    default: () => import("../components/client/grouping_busyo.vue")
  }
},
{
  path: "/client-target_selection",
  components: {
    default: () => import("../components/client/target_selection.vue")
  }
},
{
  path: "/client-registration:catchAll(.*)",
  components: {
    default: () => import("../components/client/registration.vue")
  }
},
{
  path: "/:catchAll(.*)",
  redirect:"/login"
},
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
